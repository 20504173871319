import React from 'react';
import Layout from '../components/Layout';

export const IndexPageTemplate = () => <div></div>;

IndexPageTemplate.propTypes = {
  // nada
};

const IndexPage = () => {
  return (
    <Layout>
      <IndexPageTemplate />
    </Layout>
  );
};

IndexPage.propTypes = {

};

export default IndexPage;
